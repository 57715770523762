<template>
    <div class="register-container">
        <div class="register-form-container">
            <form id="SubjectSummaryForm" @submit="handleSubmit" novalidate autocomplete="off" >
    <div class="heading">
        <h2>
            Payment Details Of Properties
        </h2>
       
    </div>
   <div class="form-group">
        <div class="row">
                  
                <div class="col-sm-4">
                    <label for="iStamp">From Date
                    </label>
                    <input type="date" name="idate"  id="idate" class="form-control" 
                        v-model="subObj.idate" />
                </div>
              
                <div class="col-sm-8">
                   <br><br>
                    <input type="button" @click="onChange()" value="View" class="btn" id ="gen" />&nbsp;&nbsp;&nbsp;
                    <input type="button" value="Print" class="btnprint" @click="printContent"/>
                </div>
                
                <br>
            </div>
        </div>
               <br> <br>
        <div id="Printcontent" v-if="reportDate">
            <div class="heading">
               <h4>{{ reportDate }}  දින දක්වා  ලද ආදායම
               </h4>
            </div>
               <div class="row" >
               <table style="width: 98%; text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; font-size: 1.3rem;" >
               <thead>
                   <tr>   
                       
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Ward Number</th> 
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; ">Estimated Income(Rs.)</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Income(Rs.)</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Discount Given(Rs.)</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Income Due(Rs.)</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Percentage of <br>Income(%)</th>
                       <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Percentage of <br>Income Due(%)</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" r in dailyreport" :key="r.id">
                  
                      
                       <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.ward }}</td>
                       <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;"> {{$formatNumber(estimatedIncomes[r.ward_id])}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                       <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ $formatNumber(r.paid_amount) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                       <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ $formatNumber(r.discount_given) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                       <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ $formatNumber(expectedIncome(estimatedIncomes[r.ward_id],r.paid_amount,r.discount_given).expectedIncome) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                       <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ $formatNumber(expectedIncome(estimatedIncomes[r.ward_id],r.paid_amount,r.discount_given).percentageIncome) }}</td>
                       <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ $formatNumber(expectedIncome(estimatedIncomes[r.ward_id],r.paid_amount,r.discount_given).perExpectedIncome) }}</td>
                     
                   </tr>
               </tbody>
           </table>
        </div>
           </div>
           <br>
          
            </form>
            
            </div>
            </div>    
</template>
<script>
import axios from 'axios';
export default {
    name: 'AssessPaidProperties',

    data() {
       return {
            nic:"",
            sabha: "",
            userLevel:"",
            subObj:{idate:"",todate:""},
            allIncomeHead:[],
            rate:"",
            reportDate:"",
            total_amount:0,
            len:0,
            total_stamp:0,
            total_dis:0,
            dailyreport:[],
            toReportDate:"",
            estimatedIncomes: {},
            // prodata:[]
       };
    },
created() {
       this.getDataFromSessionStorage()
        // this.matchRateHead()
     
   },

   methods: {
        formattedDate() {
        const options = { timeZone: 'Asia/Colombo', year: 'numeric', month: '2-digit', day: '2-digit' };
      const formatter = new Intl.DateTimeFormat('en-CA', options);
      const parts = formatter.formatToParts(new Date());

      const year = parts.find(part => part.type === 'year').value;
      const month = parts.find(part => part.type === 'month').value;
      const day = parts.find(part => part.type === 'day').value;

      return `${year}-${month}-${day}`;
              },
       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel

                       }
       },
       async onChange(){
       // get data from tempory_invoice_save
       this.topic =this.subObj.headid
       this.reportDate = this.subObj.idate
    //    this.toReportDate =this.subObj.todate
       let reportdata = await axios.get('/paymenybyward/'+this.subObj.idate+"/"+this.subObj.idate+"/"+this.sabha)
       this.dailyreport = reportdata.data

       this.dailyreport.forEach(r => this.fetchEstimatedIncome(r.ward_id));


       this.subObj.headid=""
       this.subObj.idate=""
    //    this.subObj.todate=""
    },

    async fetchEstimatedIncome(ward) {
    try {
        let response = await axios.get('/incomebyward/' + ward + "/" + this.sabha);
        if (response.data) {
            this.estimatedIncomes[ward] = response.data[0].total_sum || 0; // Direct assignment
        }
    } catch (error) {
        console.error("Error fetching estimated income:", error);
    }
},

expectedIncome: function(esti,income,discount){
    const allincome=parseFloat(income)+parseFloat(discount);
    const expecIncome=parseFloat(esti)-parseFloat(allincome);
    const perincome=(parseFloat(allincome)/parseFloat(esti))*100;
    const perexpcincome=(parseFloat(expecIncome)/parseFloat(esti))*100;
    // return expecIncome.toFixed(2)
    return {
        expectedIncome: expecIncome.toFixed(2),
        percentageIncome: perincome.toFixed(2),
        perExpectedIncome:perexpcincome.toFixed(2)
    };
},

    printContent() {
            const prtHtml = document.getElementById('Printcontent').innerHTML;
               const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                WinPrint.document.write(`    
        ${prtHtml}
    `);
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
        }
    },
   

    }
</script>

<style scoped>

.btn {
    background-color: #620a0a;   
    width: 10rem;
    height: 3rem;
    font-size: small;
    padding-top: 0.3rem;
    /* /* padding-bottom: 0.5rem;  */
} 
.btnprint {
    background-color: #e68a00;   
    width: 10rem;
    height: 3rem;
    font-size: small;
    /* padding-top: 0.3rem; */
    /* /* padding-bottom: 0.5rem;  */
} 

.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.5rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
 }
 th  {
     text-align: center;
}

.ttable, th, td {
border: 1px solid;
}
.register-container {
    /* padding: 1.5rem 9%; */
    min-height: 80vh;
}

.register-container .register-form-container {
    background: #fff;

}
.row{
    padding-left: 2%;
}
.register-container .register-form-container form {
    position: relative;
    /* padding-left: 5%; */
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 98rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.5rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}

.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 40%;
    border: none;
    padding-bottom: 0.5rem;
}
.register-container .register-form-container form .form-radio {
    margin: 2rem 0%;
    border-radius: 0.5rem;
    background: #f7f7f7;
   padding-bottom: 1rem;
    font-size: 10rem;
    color: #130f40;

    width: 10%;
    border: none;
    margin-left: 10%;
}
.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #620a0a;

}

/* .register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 20%;
    text-align: center;
    align-items: center;

    background-color : #620a0a;
    
}
.register-container .register-form-container form .btnprint {
    margin: 1rem 0;
    width: 20%;
    height: 2.5rem;
    text-align: center;
    align-items: center;

    background-color : #e68a00;
    
} */

.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 100%;

} 
.register-container .register-form-container form .hide {
    display: none;

} 
</style>