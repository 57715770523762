<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="register-container">
        <!-- <router-link to="/vouchdr" class="btn" style="background-color:green ">All Vouchers </router-link> <br><br> -->
        <div class="register-form-container">
            <form id="addIncomeHeadForm" @submit="handleSubmit" novalidate autocomplete="off">
                <div class="heading">
                    <h3><b>Payment Voucher</b></h3>
                </div>
                <div class="row">
                <div class="form-group col-sm-4">
                    <label for="vouNum">Voucher Number:
                    </label>
                    <input type="text" name="vouchNum" id="vouchNum" class="form-control"
                        v-model="vouObject.vounum" />
                    <p class="error-mess" v-if="errorObj.vouErr.length > 0">{{ errorObj.vouErr[0] }}</p>
                </div>
                <div class="form-group col-sm-4">
                    <label for="vouAmo">Amount:
                    </label>
                    <input type="text" name="vouAmount" id="vouAmount" class="form-control"
                        v-model="vouObject.vouamount" />
                    <p class="error-mess" v-if="errorObj.amountErr.length > 0">{{ errorObj.amountErr[0] }}</p>
                </div>
           
                <div class="form-group col-sm-4">
                   <label for="exHead">Expense Head/Vote:
                   </label>
                 <select v-model="vouObject.exphead" id="exHead" class="form-control" >
                       <option value="" selected disabled>
                                Choose
                       </option>
                       <option v-for="ratehead in sabhaIncomeHead" :key="ratehead.sb_rate_head "  
                           v-bind:value =ratehead.sb_rate_head >
                             {{ ratehead.sb_rate_head  }} - {{ratehead.sb_rate_head_name}}  
                       </option>
                   </select>

                   <p class="error-mess" v-if="errorObj.exheadErr.length > 0">{{ errorObj.exheadErr[0] }}</p> 
               </div>
            </div>
               <div class="form-group">
                    <label for="expDesc">Description:
                    </label>
                    <textarea name="exDes" id="exDes" class="form-control" v-model="vouObject.exdescript"></textarea>
                    <p class="error-mess" v-if="errorObj.desErr.length > 0">{{ errorObj.desErr[0] }}</p>
                </div>
                <div class="form-group">
                   <label for="isCross"><b>Type:</b> &nbsp;&nbsp;&nbsp;
                        </label>
                       <input type="radio" id="cross" name="cross" value="1" v-model="radioCheck" @change="showCrossDetail()" />
                        <label for="yes"> &nbsp; Cross Note &nbsp;&nbsp;&nbsp;</label>
                        <input type="radio" id="normal" name="normal" value="0" v-model="radioCheck" @change="hideCrossDetail()" />
                        <label for="yes"> &nbsp; Normal &nbsp;&nbsp;&nbsp;</label>
                        <p class="error-mess" v-if="errorObj.radioErr.length > 0">{{ errorObj.radioErr[0] }}</p>
                </div>
                <!-- hidden start -->
                <div class="form-group" id="crossdetail" style="display: none;">
                        <label for="icheqno">Income Head:&nbsp;&nbsp;&nbsp;
                        </label>
                        <div class="form-group">
                            <select v-model="vouObject.inhead" id="inHead" class="form-control" >
                       <option value="" selected disabled>
                                Choose
                       </option>
                       <option v-for="i in incomeheads" :key="i.sb_rate_head "  
                           v-bind:value =i.sb_rate_head >
                             {{ i.sb_rate_head  }} - {{i.sb_rate_head_name}}  
                       </option>
                   </select>
                    </div>
                    <p class="error-mess" v-if="errorObj.inheadErr.length > 0">{{errorObj.inheadErr[0] }}</p>
                    </div>
                 <!-- hidden end -->
                <div class="form-group col-sm-4 mx-auto text-center">
                    <input type="button" value="Add" class="btn" @click="tempsave()">
                  
                </div>
                <br>
                <!-- temporary table start -->
                 <div v-if="tempvouch.length>0">
                <table class="ttable" >
               <thead>
                   <tr> 
                     <th>Voucher Num</th>  
                       <th>Amount</th>
                       <th>Expense Head</th>
                       <th>Description</th>
                       <th>Type</th>
                       <th>Income Head</th>
                       <th>Remove</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" a in tempvouch" :key="a.eid">
                        <td>{{ a.voucher_num  }}</td>
                        <td>{{ a.e_amount  }}</td>
                        <td >{{ a.expense_head }}</td>
                        <td>{{ a.description   }}</td>
                        <td v-if="a.cross_states==0"></td>
                        <td v-else>Cross</td>
                        <td>{{ a.income_head }}</td>
                       <td><router-link to="" @click="deleteTemp(a.eid)"  class="fas fa-trash-alt"> </router-link></td>
                   </tr>
               </tbody>
           </table>
                 <!-- temporary table end -->
                  <p>Please check voucher details before clicking the "Save Voucher" button. If it is incorrect use Remove button to delete voucher; and add again. </p>
           <!-- table -->
           <br>
           <div class="form-group" style="padding-left: 35%;">
            <router-link  @click="saveVouchers()" to="" class=" btn" >Save Voucher</router-link>
           </div>
        </div><br>
           <div class="heading">
           <h4><b>Payment Vouchers of {{ new Date().toLocaleDateString() }}</b></h4>
           </div>
            <table class="ttable" >
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>Voucher Num</th>  
                        <th>Amount</th>
                       <th>Expense Head</th>
                       <th>Description</th>
                       <th>Type</th>
                       <th>Income Head</th>
                       <!-- <th>Remove</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" (b,index) in paginatedData " :key="index">
                        <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                        <td>{{ b.voucher_num }}</td>
                        <td>{{ b.e_amount }}</td>
                        <td>{{ b.expense_head }}</td>
                        <td>{{ b.description }}</td>
                        <td v-if="b.cross_states==0"></td>
                        <td v-else>Cross</td>
                        <td>{{ b.income_head }}</td>
                        <!-- <td>{{  }}</td> -->
                        <!-- <td><router-link to="" @click="deleteSrvice(b.id)"  class="fas fa-trash-alt"> </router-link></td> -->
                     
                    </tr>
                </tbody>
            </table>
            <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
            </form>
        </div>
    </div>
   
</template>

<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
//import { ref } from 'vue'
export default {

    name: "voucherAdd",

    data() {
        return {
            vouObject:{vounum:"",vouamount:0,exphead:"",exdescript:"",inhead:"0"},
            sabhaIncomeHead : undefined,
            errorObj: { vouErr:[],amountErr:[], exheadErr:[],desErr:[],radioErr:[],inheadErr:[] },
            radioCheck:0,
            incomeheads: undefined,
            tempvouch:[],
            dailyVouchers:[],
            arrlength:'',
            nic:"",
            sabha: "",
            userLevel:"",
            matchService:[],
            itemsPerPage: 10,
            currentPage: 1,
            invarraylen:'',
            distictvouch:[],
            distvoulen:'',
            vouchbynum:[],
        }
    },

    created() {
        this.getDataFromSessionStorage(),
        this.getVouchers(),
      this.calTotal()
    },
    computed:{

        paginatedData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    
    // Check if this.data is an array before using slice method
    if (Array.isArray(this.dailyVouchers)) {
      return this.dailyVouchers.slice(startIndex, endIndex);
    } else {
      // Return empty array or handle loading state
      return [];
    }
  },
  totalPages() {
      return Math.ceil(this.dailyVouchers.length / this.itemsPerPage);
    },
    },
   
    methods: {
        formattedDate() { const options = { timeZone: 'Asia/Colombo', year: 'numeric', month: '2-digit', day: '2-digit' };
      const formatter = new Intl.DateTimeFormat('en-CA', options);
      const parts = formatter.formatToParts(new Date());

      const year = parts.find(part => part.type === 'year').value;
      const month = parts.find(part => part.type === 'month').value;
      const day = parts.find(part => part.type === 'day').value;

      return `${year}-${month}-${day}`;
           
//            let date = new Date()
//            const year = date.getFullYear();
//             const month = String(date.getMonth() + 1).padStart(2, '0');
//             const day = String(date.getDate()).padStart(2, '0');
//         const fdate=`${year}-${month}-${day}`;
//          const options = { timeZone: 'Asia/Colombo', year: 'numeric', month: '2-digit', day: '2-digit' };
//         return fdate.toString('en-US', options);
//    //   return `${year}-${month}-${day}`;
         },

        async getDataFromSessionStorage(){
            const data = JSON.parse(sessionStorage.getItem('userData'))
            if(data){
                            this.nic = data.nic 
                            this.sabha = data.sabha
                            this.userLevel =data.userLevel
                        }
        this.sabhaIncomeHead = (await axios.get('/expenvote/'+this.sabha)).data;
        this.incomeheads = (await axios.get('/incvote/'+this.sabha)).data;
        this.tempvouch=(await axios.get('/temvouch/' +this.sabha)).data
        this.arrlength=this.tempvouch.length
        this.dailyVouchers = (await axios.get('/todayvou/'+this.formattedDate(new Date())+"/"+this.sabha)).data;
        this.radioCheck=0
        

        },
        nextPage(event) {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        prevPage(event) {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        showCrossDetail:function(){
            document.getElementById('crossdetail').style.display ='block';
        },
        hideCrossDetail:function(){
            document.getElementById('crossdetail').style.display ='none';
        },
      
        calTotal: async function(vno) {
    let crossTotal = 0;  // Use let instead of const to allow reassignment
    let i = 0;
   
    this.vouchbynum=(await axios.get('/vbyv/'+this.sabha+"/"+vno)).data
    let len = this.vouchbynum.length;
    while (i < len) {
        let amount = parseFloat(this.vouchbynum[i].e_amount);
        
        // Add only if the amount is a valid number
        if (!isNaN(amount)) {
            crossTotal += amount;  // Update crossTotal with the amount
        }
        i++;
    }
    
    return crossTotal.toFixed(2);  // Return the total as a string with 2 decimals
},

         async deleteTemp(id){
         await axios.delete("/deltemvou/"+id );
         this.$refs.alert.showAlert('success', 'Record deleted!')
         this.tempvouch=(await axios.get('/temvouch/' +this.sabha)).data
       
       },
       async saveVouchers(){
        this.arrlength=this.tempvouch.length
        for (let i = 0; i < this.arrlength; i++){
            let edata={
                    voucher_num:this.tempvouch[i].voucher_num,
                    sabha :this.tempvouch[i].sabha,
                    e_amount:this.tempvouch[i].e_amount,
                    expense_head:this.tempvouch[i].expense_head,
                    description:this.tempvouch[i].description,
                    cross_states:this.tempvouch[i].cross_states,
                    income_head:this.tempvouch[i].income_head,
                    e_date:this.formattedDate(),
                    sub_nic:this.tempvouch[i].sub_nic,
            }
            await axios.post("/insertvouc/", edata)
            this.$refs.alert.showAlert('success', 'Records added Successfully!')
            this.dailyVouchers = (await axios.get('/todayvou/'+this.formattedDate(new Date())+"/"+this.sabha)).data;
        }
          //get voucher by number disvou
          this.distictvouch=(await axios.get('/disvou/' +this.sabha)).data
        //add voucher to invoice table
       this.distvoulen =this.distictvouch.length
        for(let i=0; i< this.distvoulen; i++){
            if(this.distictvouch[i].cross_states==1){
           let cal = await this.calTotal(this.distictvouch[i].voucher_num )
        let invdata ={
            sabha:this.distictvouch[i].sabha,
            invoice_num:this.distictvouch[i].voucher_num,
            cheque_no:'',
            acc_number:'',
            cus_nic:'',
            cus_address:'',
            cus_contact:'',
            description:this.distictvouch[i].description,
            total_amount:cal,
            cheque_total:0.00,
            cash_total:0.00,
            cross_total:cal,
            total_stamp:0.00,
            total_discount:0.00,
            total_vat:0.00,
            cashier_nic:this.distictvouch[i].sub_nic,
            date:this.formattedDate(),
            type:"cross"
        }
        await axios.post("/vouchasinvoice",invdata)
    }
    }
        //add voucher to tempory_invoice table
        this.invarraylen = this.tempvouch.length
        for(let x=0; x < this.invarraylen; x++){
            if(this.tempvouch[x].cross_states==1){
            let teminvdata ={
                invoice_num:this.tempvouch[x].voucher_num,
                sabha: this.tempvouch[x].sabha,
                cus_nic:'',
                cus_name:'',
                cus_contact:'',
                cus_address:'',
                sb_rate_head: this.tempvouch[x].income_head,
                description:this.tempvouch[x].description,
                amount:this.tempvouch[x].e_amount,
                stamp:0.00,
                discount:0.00,
                cross_total:this.tempvouch[x].e_amount,
                shoptotalarrears:0.00,
                paymonth:new Date().getMonth(),
                vat:0,
                shopdid:'',
                date:this.formattedDate(),
                sub_nic:this.tempvouch[x].sub_nic
            }
            await axios.post("/voutempinvsave",teminvdata)
        }
        }
        //delete voucher from temp table
        await axios.delete("/deltempvou/"+this.sabha);
        this.tempvouch=(await axios.get('/temvouch/' +this.sabha)).data
        this.vouObject.vounum=""
        this.vouObject.e_amount=""
        this.vouObject.exphead=""
        this.vouObject.exdescript=""
        this.radioCheck=''
        this.vouObject.inhead="0"
        
       },
        async getVouchers() {
            this.dailyVouchers = (await axios.get('/todayvou/'+this.formattedDate(new Date())+"/"+this.sabha)).data;
          
        },

        resetCheckErr: function () {
            this.errorObj.vouErr = [];
            this.errorObj.amountErr=[];
            this.errorObj.exheadErr = [];
            this.errorObj.desErr = [];
            this.errorObj.radioErr=[],
            this.errorObj.inheadErr=[];
        },
        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
        checkForm: function () {
            this.resetCheckErr();

            if (!this.vouObject.vounum) {
                this.errorObj.vouErr.push("Voucher Number is required");
            }
            if (!this.vouObject.vouamount) {
                this.errorObj.amountErr.push("Voucher Amount is required");
            }

            if (!this.vouObject.exphead){
                this.errorObj.exheadErr.push("Expense head is required");
            }
            if (!this.vouObject.exdescript){
                this.errorObj.desErr.push("Description is required");
            }
            // if (!this.radioCheck){
            //     this.errorObj.radioErr.push("Please Select");
            // }
            if (!this.vouObject.inhead){
                this.errorObj.inheadErr.push("Income head is required");
            }
        },
        
        async tempsave(e) {
            this.checkForm();


            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                
                let data ={
                    voucher_num:this.vouObject.vounum,
                    sabha :this.sabha,
                    e_amount:this.vouObject.vouamount,
                    expense_head:this.vouObject.exphead,
                    description:this.vouObject.exdescript,
                    cross_states:this.radioCheck,
                    income_head:this.vouObject.inhead,
                    e_date:this.formattedDate(),
                    sub_nic:this.nic,
                 }
                  await axios.post("/savevouch/", data)
                    this.$refs.alert.showAlert('success', 'Voucher Added Successfully !')
                    this.tempvouch=(await axios.get('/temvouch/' +this.sabha)).data 
                    
                }
                }
           
        },
        components: {
        VueBasicAlert
    }

    }
    
// }
</script>

<style scoped>

/* new */
.tablrow{
padding-left: 10%;
}
.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.2rem;
 }
 th  {
     text-align: center;
}
.ttable, th, td {
border: 1px solid;
text-align: center;
}
.register-container {
    padding: 2rem 9%;
     min-height: 72.3vh;
}
.register-container .btn{
    font-size: small;
}
.register-container .register-form-container {
    background: #fff;
   
    

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 90rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.register-container .register-form-container form .form-control {
     margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
     font-size: 1.6rem; 
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
} 

.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
     color: #032a2c;

}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 50%;
    text-align: center;
    background-color:#032a2c ;
    color: #e9e4e9;
    font-size: 1.2rem;
}

.register-container .register-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group {
    margin: 0;
}

.register-container .register-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
</style>